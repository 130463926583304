import React from 'react';
import { Slider as MuiSlider } from '@mui/material';
import './Slider.css';

const CustomSlider = ({ min, max, value, onChange, showValueLabel = true }) => {
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <div className="slider-container">
      <MuiSlider
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        valueLabelDisplay={showValueLabel ? "on" : "off"}
        classes={{
          track: 'MuiSlider-track',
          rail: 'MuiSlider-rail',
        }}
      />
    </div>
  );
};

export default CustomSlider;
