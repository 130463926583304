import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, firestore as db } from '../firebase';
import { refreshUserData } from '../services/authService';
import { updateDoc, doc } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    console.log('Setting up auth state listener');
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log('Auth state changed:', user ? 'User logged in' : 'User logged out');
      if (isMounted) {
        if (user) {
          try {
            console.log('Refreshing user data');
            const userData = await refreshUserData(user.uid);
            setCurrentUser({
              ...user,
              ...userData,
              refreshUserData: async () => {
                if (isMounted) {
                  console.log('Manually refreshing user data');
                  const refreshedData = await refreshUserData(user.uid);
                  setCurrentUser(prevUser => ({ ...prevUser, ...refreshedData }));
                }
              }
            });
          } catch (error) {
            console.error('Error setting current user:', error);
            setCurrentUser(user);
          }
        } else {
          console.log('Setting current user to null');
          setCurrentUser(null);
        }
        setLoading(false);
      }
    });

    return () => {
      console.log('Cleaning up auth state listener');
      isMounted = false;
      unsubscribe();
    };
  }, []);

  const updateUser = async (updates) => {
    if (currentUser) {
      try {
        console.log('Updating user data');
        await updateDoc(doc(db, 'users', currentUser.uid), updates);
        setCurrentUser(prevUser => ({ ...prevUser, ...updates }));
      } catch (error) {
        console.error('Error updating user:', error);
        throw error;
      }
    }
  };

  const signOut = async () => {
    try {
      console.log('Signing out user');
      await auth.signOut();
      setCurrentUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const value = {
    currentUser,
    refreshUserData: currentUser ? currentUser.refreshUserData : null,
    signOut,
    updateUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
