import React, { useState } from 'react';
import { generateFlashcards } from '../../services/flashcardService';
import { uploadFile } from '../../services/fileUploadService';
import { saveFlashcards } from '../../services/firestoreService';
import { readFileContent } from '../../utils/fileProcessing';
import { Button } from "../ui/button";
import { Upload, Loader2 } from 'lucide-react';
import styles from './OnboardingFlashcardManager.module.css';

const OnboardingFlashcardManager = ({ onApiResponse }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    handleFileUpload(file);
  };

  const handleFileUpload = async (file) => {
    if (file) {
      setIsLoading(true);
      setApiResponse('');
      try {
        const uploadedFileUrl = await uploadFile(file);
        console.log('File uploaded successfully:', uploadedFileUrl);

        const fileContent = await readFileContent(file);
        
        if (!fileContent.trim()) {
          throw new Error('File content is empty');
        }

        const flashcards = await generateFlashcards(fileContent, 10); // Default to 10 flashcards

        if (flashcards.length === 0) {
          setApiResponse('No flashcards could be generated from the provided content. Please try a different file.');
        } else {
          const result = await saveFlashcards(flashcards, 'First Stack');
          onApiResponse({ flashcards: result.flashcards, stackId: result.stackId, name: 'First Stack' });
          setApiResponse('');
        }
      } catch (error) {
        console.error('File processing failed:', error);
        setApiResponse('Error: ' + error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getDisplayFileName = () => {
    if (!selectedFile) return 'Choose file';
    return selectedFile.name.length > 20 
      ? selectedFile.name.substring(0, 17) + '...' 
      : selectedFile.name;
  };

  return (
    <div className={styles.onboardingFlashcardManager}>
      <h2 className={styles.headline}>Create Your First Flashcard Stack</h2>
      <p className={styles.subtext}>Upload a file (.txt, .docx, or .pdf) to generate your first 10 flashcards instantly.</p>
      <div className={styles.fileUploadContainer}>
        <Button 
          onClick={() => document.getElementById('file-upload').click()} 
          className={styles.fileUploadBtn}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" /> 
              <span className={styles.creatingText}>Generating flashcards... Larger files take longer</span>
            </>
          ) : (
            <>
              <Upload className="mr-2 h-4 w-4" /> {getDisplayFileName()}
            </>
          )}
        </Button>
        <input
          id="file-upload"
          type="file"
          onChange={handleFileChange}
          accept=".pdf,.doc,.docx,.txt"
          className={styles.fileInput}
        />
      </div>
      {apiResponse && <p className={styles.apiResponse}>{apiResponse}</p>}
    </div>
  );
};

export default OnboardingFlashcardManager;
