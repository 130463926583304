import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AuthHeader.module.css';
import logo from '../../assets/images/header logo.png';

const AuthHeader = () => {
  return (
    <header className={styles.appHeader}>
      <div className={styles.headerContent}>
        <div className={styles.headerLeft}>
          <Link to="/" className={styles.logoContainer}>
            <img src={logo} alt="StackHeroes Logo" className={styles.logo} />
            {/* Removed logoText span since the logo image includes text */}
          </Link>
        </div>
      </div>
    </header>
  );
};

export default AuthHeader;
