import React from 'react';
import AuthHeader from '../components/AuthHeader/AuthHeader';
import Login from '../components/Login/Login';
import styles from './LoginPage.module.css';

const LoginPage = () => {
  return (
    <div className={styles.loginPage}>
      <AuthHeader />
      <div className={styles.loginContentWrapper}>
        <h1 className={styles.loginTitle}>Log In</h1>
        <Login />
      </div>
    </div>
  );
};

export default LoginPage;
