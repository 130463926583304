import React from 'react';
import { Layers, X } from 'lucide-react';
import './LeftPanel.css';

const LeftPanel = ({ stacks, onStackSelect, selectedStackId, isMobile, onClose }) => {
    const StackList = ({ stacks, onStackSelect, selectedStackId }) => (
        <ul className={`stack-list ${isMobile ? 'mobile-stack-list' : ''}`}>
            {stacks.map((stack) => (
                <li
                    key={stack.id}
                    className={`stack-item ${selectedStackId === stack.id ? 'selected' : ''}`}
                    onClick={() => onStackSelect(stack.id, stack.name)}
                >
                    <Layers size={18} className="stack-icon" />
                    <span className="stack-name left-panel-stack-name">{stack.name}</span>
                </li>
            ))}
        </ul>
    );

    if (isMobile) {
        return (
            <div className="mobile-stack-panel">
                <div className="mobile-panel-header">
                    <h2 className="panel-title">Select a Stack</h2>
                    <button className="close-button" onClick={onClose}>
                        <X size={24} />
                    </button>
                </div>
                <StackList stacks={stacks} onStackSelect={onStackSelect} selectedStackId={selectedStackId} />
            </div>
        );
    }

    return (
        <div className="left-panel">
            <h2 className="panel-title">Your Stacks</h2>
            <StackList stacks={stacks} onStackSelect={onStackSelect} selectedStackId={selectedStackId} />
        </div>
    );
};

export default LeftPanel;
