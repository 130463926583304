console.log('flashcardService.js is being loaded');

const FIREBASE_FUNCTION_URL = 'https://us-central1-stackheroes-native.cloudfunctions.net/generateFlashcards';

export const generateFlashcards = async (notes, numFlashcards) => {
    console.log('generateFlashcards function called');
    console.log('Sending request to Firebase Function with notes:', notes);
    console.log('Number of flashcards requested:', numFlashcards);

    try {
        const response = await fetch(FIREBASE_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                notes,
                model: 'gpt-4o-mini',
                systemMessage: 'You are a helpful assistant that generates flashcards from notes.',
                userMessage: `Generate exactly ${numFlashcards} flashcards from the following notes:\n\n${notes}\n\nFormat the flashcards as follows, numbering them:\n\n  1. Question: [First question]\n     Answer: [First answer]\n\n  2. Question: [Second question]\n     Answer: [Second answer]\n\n  Continue this structure until ${numFlashcards} flashcards have been generated. If there is insufficient information, indicate "Insufficient data" in both the question and answer fields.`,
                maxTokens: 16000,
                temperature: 0.3,
                numFlashcards,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Received response from Firebase Function:', JSON.stringify(data, null, 2));

        if (data.content) {
            console.log('Flashcards text:', data.content);
            
            const flashcards = data.content.split('\n\n')
                .filter(card => card.trim() !== '')
                .map(card => {
                    const [questionPart, answerPart] = card.split('\nAnswer: ');
                    return {
                        question: questionPart.replace('Question: ', '').trim(),
                        answer: answerPart ? answerPart.trim() : 'Insufficient data'
                    };
                })
                .slice(0, numFlashcards);

            console.log('Generated flashcards:', JSON.stringify(flashcards, null, 2));
            return flashcards;
        } else {
            console.error('No content in the response');
            return Array(numFlashcards).fill({ question: 'Insufficient data', answer: 'Insufficient data' });
        }
    } catch (error) {
        console.error('Error generating flashcards:', error);
        throw new Error('Failed to generate flashcards. Please try again later.');
    }
};