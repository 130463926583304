export const ranks = [
    { id: 1, rank: 'Viking Wanderer' },
    { id: 2, rank: 'Shield-Bearer' },
    { id: 3, rank: 'Axe Forager' },
    { id: 4, rank: 'Viking Pathfinder' },
    { id: 5, rank: 'Battle Shouter' },
    { id: 6, rank: 'Frost Raider' },
    { id: 7, rank: 'Berserker of the North' },
    { id: 8, rank: 'Ironhide Guardian' },
    { id: 9, rank: 'Thunder Herald' },
    { id: 10, rank: 'Storm Warlord' },
    { id: 11, rank: 'Squire of the Banner' },
    { id: 12, rank: 'Sword Apprentice' },
    { id: 13, rank: 'Defender of the Keep' },
    { id: 14, rank: 'Knight of the Dawn' },
    { id: 15, rank: 'Herald of Valor' },
    { id: 16, rank: 'Champion of the Lance' },
    { id: 17, rank: 'Guardian of the Grail' },
    { id: 18, rank: 'Siege Commander' },
    { id: 19, rank: 'Dragon Slayer' },
    { id: 20, rank: 'High Paladin of Light' },
    { id: 21, rank: 'Apprentice of Sparks' },
    { id: 22, rank: 'Scroll Keeper' },
    { id: 23, rank: 'Arcane Initiate' },
    { id: 24, rank: 'Elemental Conjurer' },
    { id: 25, rank: 'Glyph Weaver' },
    { id: 26, rank: 'Time Bender' },
    { id: 27, rank: 'Master of Illusions' },
    { id: 28, rank: 'Rune Summoner' },
    { id: 29, rank: 'Archmage of the Arcane Tower' },
    { id: 30, rank: 'Sorcerer Supreme' },
    { id: 31, rank: 'Woodland Scout' },
    { id: 32, rank: 'Tracker of the Glade' },
    { id: 33, rank: 'Bowstring Whisperer' },
    { id: 34, rank: 'Forest Stalker' },
    { id: 35, rank: 'Arrow Dancer' },
    { id: 36, rank: 'Hawk-Eyed Marksman' },
    { id: 37, rank: 'Shadow of the Grove' },
    { id: 38, rank: 'Sentinel of the Wilds' },
    { id: 39, rank: 'Moonlit Hunter' },
    { id: 40, rank: 'Ranger of the Eternal Forest' },
    { id: 41, rank: 'Forge Apprentice' },
    { id: 42, rank: 'Iron Carver' },
    { id: 43, rank: 'Stone Ward' },
    { id: 44, rank: 'Hammer Holder' },
    { id: 45, rank: 'Deep Mine Guardian' },
    { id: 46, rank: 'Brewmaster of Legends' },
    { id: 47, rank: 'Siege Architect' },
    { id: 48, rank: 'Anvil Lord' },
    { id: 49, rank: 'Master of the Mithril Hall' },
    { id: 50, rank: 'King Under the Mountain' },
    { id: 51, rank: 'Deckhand Drifter' },
    { id: 52, rank: 'Storm Navigator' },
    { id: 53, rank: 'Black Powder Specialist' },
    { id: 54, rank: 'Skull Brander' },
    { id: 55, rank: 'Sea Swindler' },
    { id: 56, rank: 'Cannon Master' },
    { id: 57, rank: 'Ghost Ship Captain' },
    { id: 58, rank: 'Treasure Hunter Extraordinaire' },
    { id: 59, rank: 'Corsair Overlord' },
    { id: 60, rank: 'Pirate King of the Seven Seas' },
    { id: 61, rank: 'Gear Spinner' },
    { id: 62, rank: 'Steam Piston Mechanic' },
    { id: 63, rank: 'Cogs-and-Wires Specialist' },
    { id: 64, rank: 'Clockwork Tinkerer' },
    { id: 65, rank: 'Brass Cannoneer' },
    { id: 66, rank: 'Sky Zeppelin Pilot' },
    { id: 67, rank: 'Lightning Coil Engineer' },
    { id: 68, rank: 'Automaton Constructor' },
    { id: 69, rank: 'Chief Alchemist of Timepieces' },
    { id: 70, rank: 'Steam Emperor' },
    { id: 71, rank: 'Data Scrambler' },
    { id: 72, rank: 'Neon Hacker' },
    { id: 73, rank: 'Street Enforcer' },
    { id: 74, rank: 'Mindjack Specialist' },
    { id: 75, rank: 'Synthblade User' },
    { id: 76, rank: 'Gridrunner Elite' },
    { id: 77, rank: 'Chrome Assassin' },
    { id: 78, rank: 'Network Saboteur' },
    { id: 79, rank: 'Shadow Protocol' },
    { id: 80, rank: 'Cyber Overlord' },
    { id: 81, rank: 'Starship Cadet' },
    { id: 82, rank: 'Asteroid Miner' },
    { id: 83, rank: 'Alien Envoy' },
    { id: 84, rank: 'Celestial Navigator' },
    { id: 85, rank: 'Starforge Officer' },
    { id: 86, rank: 'Void Commander' },
    { id: 87, rank: 'Warp Traveler' },
    { id: 88, rank: 'Planetary Emissary' },
    { id: 89, rank: 'Galactic Ambassador' },
    { id: 90, rank: 'Starborn Admiral' },
    { id: 91, rank: 'Cosmic Seer' },
    { id: 92, rank: 'Master of the Quantum Rift' },
    { id: 93, rank: 'Herald of the Nebula' },
    { id: 94, rank: 'Timewalker' },
    { id: 95, rank: 'Guardian of the Multiverse' },
    { id: 96, rank: 'Ascended One of the Stars' },
    { id: 97, rank: 'Keeper of the Eternal Flame' },
    { id: 98, rank: 'Titan of the Infinite Void' },
    { id: 99, rank: 'Nexus Warden' },
    { id: 100, rank: 'Reality Architect' }
];

export const getRankByLevel = (level) => {
    console.log('Getting rank for level:', level);
    const rank = ranks.find(r => r.id === level);
    console.log('Found rank:', rank);
    return rank?.rank || 'Unknown Rank';
};
