import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { updateUserProfile, getUserProfile } from '../services/authService';
import OnboardingFlashcardManager from '../components/OnboardingFlashcardManager/OnboardingFlashcardManager';
import styles from './WelcomePage.module.css';

const WelcomePage = () => {
  const [stage, setStage] = useState(2); // Start at stage 2 (Set Nickname)
  const [nickname, setNickname] = useState('');
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else {
      // Check if the user already has a nickname
      getUserProfile(currentUser.uid).then(profile => {
        if (profile.nickname && profile.nickname !== 'Guest') {
          setStage(3);
          setNickname(profile.nickname);
        }
      });
    }
  }, [currentUser, navigate]);

  const handleNicknameSubmit = async (e) => {
    e.preventDefault();
    if (nickname.trim()) {
      try {
        await updateUserProfile(currentUser.uid, { nickname: nickname.trim() });
        setStage(3);
      } catch (error) {
        setError('Failed to update nickname. Please try again.');
      }
    } else {
      setError('Please enter a nickname.');
    }
  };

  const handleApiResponse = (response) => {
    if (response && response.stackId) {
      // Navigate to the learning page of the newly created stack
      navigate(`/study/${response.stackId}`);
    }
  };

  return (
    <div className={styles.welcomePage}>
      <div className={styles.progressBar}>
        <div className={styles.progressTrack}>
          <div className={styles.progressFill} style={{width: `${(stage - 1) * 50}%`}}></div>
        </div>
        <div className={styles.progressSteps}>
          <div className={styles.progressStepContainer}>
            <div className={`${styles.progressStep} ${stage >= 1 ? styles.active : ''}`}>1</div>
            <div className={styles.progressStepTitle}>Sign Up</div>
          </div>
          <div className={styles.progressStepContainer}>
            <div className={`${styles.progressStep} ${stage >= 2 ? styles.active : ''}`}>2</div>
            <div className={styles.progressStepTitle}>Nickname</div>
          </div>
          <div className={styles.progressStepContainer}>
            <div className={`${styles.progressStep} ${stage >= 3 ? styles.active : ''}`}>3</div>
            <div className={styles.progressStepTitle}>First Stack</div>
          </div>
        </div>
      </div>

      <div className={styles.stageContent}>
        {stage === 2 && (
          <div className={styles.nicknameForm}>
            <h2 className={styles.headline}>Choose Your Nickname</h2>
            <p className={styles.subtext}>This will be your display name in the app</p>
            {error && <p className={styles.error}>{error}</p>}
            <form onSubmit={handleNicknameSubmit}>
              <input
                type="text"
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                placeholder="Enter your nickname"
                required
              />
              <button type="submit">Continue</button>
            </form>
          </div>
        )}

        {stage === 3 && (
          <div className={styles.createStack}>
            <OnboardingFlashcardManager
              onApiResponse={handleApiResponse}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomePage;
