import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LandingHeader from '../components/LandingHeader/LandingHeader';
import styles from './LandingPage.module.css';
import landingImage from '../assets/images/landing-image-v1.jpg';
import googleLogo from '../assets/images/g-logo1.png';
import { signInWithGoogle, signUpWithEmail, signInWithEmail } from '../services/authService';
import { useAuth } from '../contexts/AuthContext';
import { updateUserProfile, getUserProfile } from '../services/authService';

const LandingPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const auth = useAuth();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const user = await signUpWithEmail(email, password);
      if (user) {
        if (auth && typeof auth.setCurrentUser === 'function') {
          auth.setCurrentUser(user);
        }
        await updateUserProfile(user.uid, { isNewUser: true });
        navigate('/welcome');
      } else {
        setError('Failed to sign up. Please try again.');
      }
    } catch (error) {
      console.error("Error signing up with email", error);
      if (error.code === 'auth/email-already-in-use') {
        setError('This email is already in use. Please try logging in or use a different email.');
      } else {
        setError('An error occurred during sign up. Please try again.');
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { user, isNewUser } = await signInWithGoogle();
      if (user) {
        if (auth && typeof auth.setCurrentUser === 'function') {
          auth.setCurrentUser(user);
        }
        if (isNewUser) {
          navigate('/welcome');
        } else {
          navigate('/home');
        }
      } else {
        setError('Failed to sign in with Google. Please try again.');
      }
    } catch (error) {
      console.error("Error signing in with Google", error);
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const user = await signInWithEmail(email, password);
      if (user) {
        if (auth && typeof auth.setCurrentUser === 'function') {
          auth.setCurrentUser(user);
        }
        const userProfile = await getUserProfile(user.uid);
        if (userProfile.isNewUser) {
          navigate('/welcome');
        } else {
          navigate('/home');
        }
      } else {
        setError('Failed to log in. Please check your credentials and try again.');
      }
    } catch (error) {
      console.error("Error logging in with email", error);
      setError('Failed to log in. Please check your credentials and try again.');
    }
  };

  return (
    <div className={styles.landingPage}>
      <LandingHeader />
      <div className={styles.landingMain}>
        <div className={styles.contentSection}>
          <h1>Power Up Your Study Game</h1>
          <p>
            StackHero: The AI that turns your study materials -
            into flashcards instantly and for free.
          </p>
          <div className={styles.signUpOptions}>
            <form onSubmit={handleSignUp} className={styles.signUpForm}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Create password"
                required
              />
              <button type="submit" className={styles.signUpBtn}>Sign Up With Email</button>
            </form>
            <div className={styles.orDivider}>
              <span>Or</span>
            </div>
            <button className={styles.googleSignInBtn} onClick={handleGoogleSignIn}>
              <img src={googleLogo} alt="Google logo" className={styles.socialIcon} />
              Sign up with Google
            </button>
            {error && <p className={styles.errorMessage}>{error}</p>}
          </div>
        </div>
        <div className={styles.imageSection}>
          <img src={landingImage} alt="AI Study Helper" className={styles.landingImage} />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
