import pdfjsLib from '../pdfjs-config';
import mammoth from 'mammoth';

export const readFileContent = async (file) => {
    if (file.type === 'application/pdf') {
        return await readPdfContent(file);
    } else if (file.type === 'text/plain') {
        return await readTextContent(file);
    } else if (file.name.endsWith('.docx')) {
        return await readDocxContent(file);
    } else if (file.name.endsWith('.doc')) {
        return await readDocContent(file);
    }
    throw new Error('Unsupported file type');
};

const readPdfContent = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
            try {
                const typedarray = new Uint8Array(event.target.result);
                const pdf = await pdfjsLib.getDocument(typedarray).promise;
                let fullText = '';
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const content = await page.getTextContent();
                    const text = content.items.map(item => item.str).join(' ');
                    fullText += text + '\n';
                }
                resolve(fullText);
            } catch (error) {
                reject(error);
            }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};

const readDocxContent = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
            try {
                const arrayBuffer = event.target.result;
                const result = await mammoth.extractRawText({ arrayBuffer });
                resolve(result.value);
            } catch (error) {
                reject(error);
            }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};

const readTextContent = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result);
        };
        reader.onerror = reject;
        reader.readAsText(file);
    });
};

const readDocContent = async (file) => {
    // Implement DOC file reading logic here
    throw new Error('DOC file reading not implemented');
};