import { firestore as db, auth } from '../firebase';
import { 
    collection, 
    doc, 
    getDocs, 
    query, 
    where, 
    limit, 
    updateDoc, 
    deleteDoc, 
    runTransaction, 
    getDoc,
    setDoc,
    addDoc,
    writeBatch,
    serverTimestamp,
    orderBy  // Add this import
} from 'firebase/firestore';

const checkUserAuth = () => {
  const user = auth.currentUser;
  if (!user) {
    console.log('No authenticated user');
    throw new Error('No authenticated user');
  }
  return user;
};

// Add these constants at the top with other imports
const STACK_LIMITS = {
  FREE: 10,
  PREMIUM: 100
};

export const saveFlashcards = async (flashcards, stackName, isSubscribed) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    // Add stack limit check
    const currentStackCount = await getUserStackCount();
    const stackLimit = isSubscribed ? STACK_LIMITS.PREMIUM : STACK_LIMITS.FREE;
    
    if (currentStackCount >= stackLimit) {
      throw new Error(
        isSubscribed 
          ? 'You have reached the maximum limit of 100 stacks. Please delete some stacks to create new ones.'
          : 'Free users can create up to 10 stacks. Please upgrade to premium for more stacks or delete existing ones.'
      );
    }

    const stacksCollection = collection(db, 'users', user.uid, 'stacks');
    
    try {
      const batch = writeBatch(db);

      // Create a new stack
      const stackRef = doc(stacksCollection);
      const now = new Date();
      const expirationDate = isSubscribed ? null : new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours from now

      batch.set(stackRef, {
        name: stackName,
        createdAt: serverTimestamp(),
        userId: user.uid,
        expiresAt: expirationDate
      });

      // Add flashcards to the stack, limiting to 15 for unsubscribed users
      const flashcardsCollection = collection(stackRef, 'flashcards');
      const limitedFlashcards = isSubscribed ? flashcards : flashcards.slice(0, 15);
      const savedFlashcards = limitedFlashcards.map(flashcard => {
        const flashcardRef = doc(flashcardsCollection);
        const flashcardData = {
          id: flashcardRef.id,
          question: flashcard.question,
          answer: flashcard.answer,
          createdAt: serverTimestamp(),
          expiresAt: expirationDate
        };
        batch.set(flashcardRef, flashcardData);
        return flashcardData;
      });

      await batch.commit();

      console.log('Flashcards saved successfully');
      return { stackId: stackRef.id, flashcards: savedFlashcards };
    } catch (error) {
      console.error('Error saving flashcards:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error saving flashcards:', error);
    throw error;
  }
};

// Add this new function
export const getUserStackCount = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');
  
  const stacksCollection = collection(db, 'users', user.uid, 'stacks');
  const snapshot = await getDocs(stacksCollection);
  return snapshot.docs.length;
};

// Add a new function to update expiration times
export const updateExpirationTimes = async (userId, isSubscribed) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const stacksCollection = collection(db, 'users', user.uid, 'stacks');
  const stacks = await getDocs(stacksCollection);

  const batch = writeBatch(db);

  const expirationTime = isSubscribed ? null : new Date(Date.now() + 24 * 60 * 60 * 1000); // 24 hours from now

  for (const stackDoc of stacks.docs) {
    const stackRef = doc(stacksCollection, stackDoc.id);
    const flashcardsCollection = collection(stackRef, 'flashcards');
    const flashcards = await getDocs(flashcardsCollection);

    batch.update(stackRef, { 
      expiresAt: expirationTime,
      updatedAt: serverTimestamp()
    });

    flashcards.forEach((flashcardDoc) => {
      const flashcardRef = doc(flashcardsCollection, flashcardDoc.id);
      batch.update(flashcardRef, { 
        expiresAt: expirationTime,
        updatedAt: serverTimestamp()
      });
    });
  }

  await batch.commit();
  console.log(`Updated expiration times for user ${userId}. Subscription status: ${isSubscribed}`);
};

export const getStacks = async (userId) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const stacksRef = collection(db, 'users', user.uid, 'stacks');
    const q = query(
      stacksRef,
      orderBy('createdAt', 'desc') // Simply order by creation date
    );

    const querySnapshot = await getDocs(q);
    const now = new Date();
    
    // Filter expired stacks in memory
    return querySnapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      .filter(stack => {
        if (!stack.expiresAt) return true;
        const expirationDate = stack.expiresAt.toDate();
        return now < expirationDate;
      });
  } catch (error) {
    console.error('Error fetching stacks:', error);
    throw error;
  }
};

export const getFlashcardsInStack = async (stackId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const flashcardsCollection = collection(db, 'users', user.uid, 'stacks', stackId, 'flashcards');
  const snapshot = await getDocs(flashcardsCollection);
  
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

export const updateStack = async (stackId, data) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  let stackRef;
  if (stackId) {
    stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
    await updateDoc(stackRef, data);
  } else {
    const stacksCollection = collection(db, 'users', user.uid, 'stacks');
    stackRef = await addDoc(stacksCollection, { ...data, createdAt: new Date() });
  }

  const updatedStack = await getDoc(stackRef);
  return { id: updatedStack.id, ...updatedStack.data() };
};

export const deleteStack = async (stackId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
  const flashcardsRef = collection(stackRef, 'flashcards');

  // Delete all flashcards in the stack
  const flashcardDocs = await getDocs(flashcardsRef);
  const batch = writeBatch(db);
  flashcardDocs.forEach((doc) => {
    batch.delete(doc.ref);
  });

  // Delete the stack document
  batch.delete(stackRef);

  // Commit the batch
  await batch.commit();
};

export const updateFlashcard = async (stackId, flashcardId, data) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const flashcardRef = doc(db, 'users', user.uid, 'stacks', stackId, 'flashcards', flashcardId);
  await updateDoc(flashcardRef, data);
};

export const deleteFlashcard = async (stackId, flashcardId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const flashcardRef = doc(db, 'users', user.uid, 'stacks', stackId, 'flashcards', flashcardId);
  await deleteDoc(flashcardRef);
};

export const deleteStackAndFlashcards = async (stackId) => {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);

    try {
        await runTransaction(db, async (transaction) => {
            // Get the stack document
            const stackDoc = await transaction.get(stackRef);
            if (!stackDoc.exists()) {
                throw new Error('Stack does not exist');
            }

            // Delete all flashcards in the stack
            const flashcardsCollection = collection(stackRef, 'flashcards');
            const flashcardsSnapshot = await getDocs(flashcardsCollection);
            flashcardsSnapshot.docs.forEach((flashcardDoc) => {
                transaction.delete(flashcardDoc.ref);
            });

            // Delete the stack document
            transaction.delete(stackRef);
        });

        console.log('Stack and associated flashcards deleted successfully');
    } catch (error) {
        console.error("Error in deleteStackAndFlashcards:", error);
        throw error;
    }
};

export const getUserSubscriptionStatus = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  
  if (userDoc.exists()) {
    return userDoc.data().isSubscribed || false;
  } else {
    console.error('User document not found');
    return false;
  }
};

export const getStackWithFirstQuestion = async (stackId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
  const stackDoc = await getDoc(stackRef);
  
  if (!stackDoc.exists()) throw new Error('Stack not found');

  const stackData = stackDoc.data();
  const flashcardsCollection = collection(stackRef, 'flashcards');
  const flashcardsQuery = query(flashcardsCollection);
  const flashcardsSnapshot = await getDocs(flashcardsQuery);
  
  const firstQuestion = flashcardsSnapshot.docs[0]?.data().question || 'No questions yet';
  const flashcardCount = flashcardsSnapshot.size;

  return {
    id: stackDoc.id,
    ...stackData,
    createdAt: stackData.createdAt ? stackData.createdAt.toDate() : null,
    expiresAt: stackData.expiresAt ? stackData.expiresAt.toDate() : null,
    firstQuestion,
    flashcardCount,
  };
};

export const getStackWithFlashcards = async (stackId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
  const stackDoc = await getDoc(stackRef);
  
  if (!stackDoc.exists()) throw new Error('Stack not found');

  const stackData = stackDoc.data();
  const flashcardsCollection = collection(stackRef, 'flashcards');
  const flashcardsSnapshot = await getDocs(flashcardsCollection);
  
  const flashcards = flashcardsSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));

  return {
    id: stackDoc.id,
    ...stackData,
    flashcards,
  };
};

export const getUserNickname = async (userId) => {
  try {
    checkUserAuth();
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (userDoc.exists()) {
      return userDoc.data().nickname || 'User';
    } else {
      console.error('User document not found');
      return 'User';
    }
  } catch (error) {
    console.error('Error fetching user nickname:', error);
    return 'User';
  }
};

export const getUserStats = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  
  if (userDoc.exists()) {
    const userData = userDoc.data();
    return {
      points: userData.points || 0,
      level: userData.level || 1,
      levelProgress: userData.levelProgress || 0,
      consecutiveDays: userData.consecutiveDays || 0,
      lastLoginDate: userData.lastLoginDate ? userData.lastLoginDate.toDate() : new Date(),
      consecutiveDaysMultiplier: userData.consecutiveDaysMultiplier || 1,
      consecutiveStacksMultiplier: userData.consecutiveStacksMultiplier || 1,
      stackLengthMultiplier: userData.stackLengthMultiplier || 1, // Add this line
      completedStacksToday: userData.completedStacksToday || 0,
    };
  } else {
    console.error('User document not found');
    return {
      points: 0,
      level: 1,
      levelProgress: 0,
      consecutiveDays: 0,
      lastLoginDate: new Date(),
      consecutiveDaysMultiplier: 1,
      consecutiveStacksMultiplier: 1,
      stackLengthMultiplier: 1, // Add this line
      completedStacksToday: 0,
    };
  }
};

export const updateUserStats = async (userId, stats) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, stats);
};

export const updateStackName = async (stackId, newName) => {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
    await updateDoc(stackRef, { name: newName });
};

// Add this function
export const checkStackExpiration = async (stackId) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
    const stackDoc = await getDoc(stackRef);
    
    if (!stackDoc.exists()) return false;
    
    const stackData = stackDoc.data();
    if (!stackData.expiresAt) return true;
    
    const now = new Date();
    const expirationDate = stackData.expiresAt.toDate();
    
    return now < expirationDate;
  } catch (error) {
    console.error('Error checking stack expiration:', error);
    throw error;
  }
};

// Add this function to handle consecutive stack multiplier updates
export const updateConsecutiveStackMultiplier = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  
  if (!userDoc.exists()) return;
  
  const userData = userDoc.data();
  const now = new Date();
  const lastStackCompletion = userData.lastStackCompletionDate?.toDate();
  const hoursSinceLastCompletion = lastStackCompletion 
    ? (now - lastStackCompletion) / (1000 * 60 * 60) 
    : 24;

  // Reset if more than 1 hour has passed
  if (hoursSinceLastCompletion > 1) {
    await updateDoc(userRef, {
      consecutiveStacksMultiplier: 1,
      completedStacksToday: 1, // Reset to 1 since this is a new completion
      lastStackCompletionDate: now
    });
    return 1; // Return new multiplier
  } else {
    // Increment multiplier if within the hour
    const newCompletedStacksToday = (userData.completedStacksToday || 0) + 1;
    const newMultiplier = Math.min(1 + (newCompletedStacksToday * 0.2), 3); // Cap at 3x

    await updateDoc(userRef, {
      consecutiveStacksMultiplier: newMultiplier,
      completedStacksToday: newCompletedStacksToday,
      lastStackCompletionDate: now
    });
    return newMultiplier;
  }
};
