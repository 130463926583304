import React from 'react';
import { Link } from 'react-router-dom';
import styles from './LandingHeader.module.css';
import logo from '../../assets/images/header logo.png';

const LandingHeader = () => {
  return (
    <header className={styles.appHeader}>
      <div className={styles.headerContent}>
        <div className={styles.headerLeft}>
          <Link to="/" className={styles.logoContainer}>
            <img src={logo} alt="StackHeroes Logo" className={styles.logo} />
            {/* Removed logoText span since the logo image includes text */}
          </Link>
        </div>
        <nav className={styles.headerRight}>
          <Link to="/login" className={styles.signInBtn}>Sign In</Link>
        </nav>
      </div>
    </header>
  );
};

export default LandingHeader;
