import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import { Check } from 'lucide-react';
import Header from '../components/Header/Header'; // Import the Header component
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import './SubscriptionPage.css';
import helmetImage from '../assets/images/helmet_light purple.png'; // Update this line near the top of the file

const SubscriptionPage = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [paypalConfig, setPaypalConfig] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { currentUser, updateUser } = useAuth();
  const functions = getFunctions();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPayPalConfig = async () => {
      try {
        const response = await axios.get('https://us-central1-stackheroes-native.cloudfunctions.net/getPayPalConfig', {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
        setPaypalConfig(response.data);
      } catch (err) {
        console.error('Error fetching PayPal config:', err);
        setError('Unable to load PayPal. Please try again later.');
      }
    };

    const checkSubscriptionStatus = async () => {
      if (!currentUser) return;
      try {
        const checkStatus = httpsCallable(functions, 'checkSubscriptionStatus');
        const result = await checkStatus({ userId: currentUser.uid });
        setIsSubscribed(result.data.isSubscribed);
      } catch (err) {
        console.error('Error checking subscription status:', err);
        setError('Unable to check subscription status. Please try again later.');
      }
    };

    fetchPayPalConfig();
    checkSubscriptionStatus();
  }, [currentUser, functions]);

  const handleApprove = async (data, actions) => {
    try {
      const response = await axios.post('https://us-central1-stackheroes-native.cloudfunctions.net/capturePayPalSubscription', {
        subscriptionID: data.subscriptionID,
        userID: currentUser.uid
      });
      if (response.data.success) {
        setSuccess(true);
        setIsSubscribed(true);
        await updateUser({ isSubscribed: true });
        navigate('/home');
      } else {
        setError('There was an error activating your subscription: ' + response.data.message);
      }
    } catch (err) {
      console.error('Error capturing subscription:', err.response ? err.response.data : err.message);
      setError('There was an error processing your subscription.');
    }
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!paypalConfig) {
    return <div>Loading...</div>;
  }

  return (
    <div className="subscription-page">
      <Header /> {/* Add the Header component here */}
      <div className="subscription-container">
        <div className="subscription-image-container">
          <img src={helmetImage} alt="Subscription Helmet" className="subscription-image" />
        </div>
        <div className="subscription-card-container">
          <Card className="subscription-card">
            <CardHeader>
              <CardTitle className="subscription-title">Subscription Plan</CardTitle>
              <p className="subtitle">Advanced features for serious learners</p>
            </CardHeader>
            <CardContent>
              <ul className="features-list">
                <li><Check size={20} /> Up to 50 flashcards per stack</li>
                <li><Check size={20} /> Create unlimited stacks</li>
                <li><Check size={20} /> No expiration date on stacks</li>
                <li><Check size={20} /> Cancel at any time</li>
              </ul>
              <div className="price">$2.99 / month</div>
              {success && <div className="success-message">Thank you for subscribing!</div>}
              {isSubscribed ? (
                <div className="already-subscribed">You are already subscribed!</div>
              ) : (
                !success && paypalConfig && (
                  <div className="payment-options">
                    <PayPalScriptProvider options={{
                      'client-id': paypalConfig.clientId,
                      currency: 'USD',
                      intent: 'subscription',
                      vault: true,
                      // The environment will automatically switch to production 
                      // when using the production client-id
                    }}>
                      <PayPalButtons
                        style={{ layout: "vertical" }}
                        createSubscription={(data, actions) => {
                          return actions.subscription.create({
                            plan_id: paypalConfig.planId
                          });
                        }}
                        onApprove={handleApprove}
                        onError={(err) => {
                          console.error('PayPal error', err);
                          setError('There was an error processing your subscription. Please try again.');
                        }}
                      />
                    </PayPalScriptProvider>
                  </div>
                )
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
