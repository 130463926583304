import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, signInWithEmail, getUserProfile, updateUserProfile } from '../../services/authService';
import { useAuth } from '../../contexts/AuthContext';
import styles from './Login.module.css';
import googleLogo from '../../assets/images/g-logo1.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/home');
    }
  }, [currentUser, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const user = await signInWithEmail(email, password);
      setCurrentUser(user);
      const userProfile = await getUserProfile(user.uid);
      if (userProfile.isNewUser) {
        await updateUserProfile(user.uid, { isNewUser: false });
        navigate('/welcome');
      } else {
        navigate('/home');
      }
    } catch (error) {
      console.error("Error logging in with email", error);
      setError('Failed to log in. Please check your credentials and try again.');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { user, isNewUser } = await signInWithGoogle();
      if (user) {
        setCurrentUser(user);
        if (isNewUser) {
          navigate('/welcome');
        } else {
          navigate('/home');
        }
      } else {
        setError('Failed to sign in with Google. Please try again.');
      }
    } catch (error) {
      console.error("Error signing in with Google", error);
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  return (
    <div className={styles.loginContainer}>
      {error && <p className={styles.errorMessage}>{error}</p>}
      <form className={styles.loginForm} onSubmit={handleLogin}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit" className={styles.loginBtn} style={{backgroundColor: '#3a0ca3'}}>
          Log In With Email
        </button>
      </form>
      <div className={styles.orDivider}>
        <span>Or</span>
      </div>
      <button className={styles.googleSignInBtn} onClick={handleGoogleSignIn}>
        <img src={googleLogo} alt="Google logo" className={styles.socialIcon} />
        Log in with Google
      </button>
    </div>
  );
};

export default Login;
