import React, { useState, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { debounce } from 'lodash'; // Make sure to install lodash if not already present
import { handleSubscriptionCancellation } from '../services/authService';

function SubscriptionCancellation() {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const { currentUser } = useAuth();
  const functions = getFunctions();
  const cancelSubscriptionRef = useRef(null);

  cancelSubscriptionRef.current = useCallback(async () => {
    if (currentUser && !isProcessing) {
      setIsProcessing(true);
      try {
        setMessage('Cancelling subscription...');
        const cancelSubscriptionFunction = httpsCallable(functions, 'cancelSubscription');
        const result = await cancelSubscriptionFunction();
        
        // Use the new function instead of directly calling updateExpirationTimes
        await handleSubscriptionCancellation(currentUser);
        
        setMessage(result.data.message + ' Your flashcards will be available for the next 24 hours.');
        setError('');
      } catch (error) {
        setMessage('');
        setError(`Error cancelling subscription: ${error.message}`);
        console.error('Detailed error:', error);
      } finally {
        setIsProcessing(false);
      }
    }
  }, [currentUser, functions, isProcessing]);

  const debouncedCancelSubscription = useCallback(
    debounce(() => {
      cancelSubscriptionRef.current();
    }, 300),
    []
  );

  return (
    <div>
      <h2>Subscription Cancellation</h2>
      {message && <p>{message}</p>}
      {error && <p style={{color: 'red'}}>{error}</p>}
      <button 
        onClick={debouncedCancelSubscription} 
        disabled={isProcessing}
      >
        {isProcessing ? 'Processing...' : 'Cancel Subscription'}
      </button>
    </div>
  );
}

export default SubscriptionCancellation;