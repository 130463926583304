import React from 'react';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight, Check, X, Loader } from 'lucide-react';
import { Button } from "../ui/button";
import { Card } from "../ui/card";
import './Flashcards.css';

const isRTL = (text) => {
  const rtlChars = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
  return rtlChars.test(text);
};

export default function Flashcards({ flashcards: initialFlashcards, onAnswer, onComplete }) {
  const [flashcards, setFlashcards] = useState(initialFlashcards);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [sessionKnownCount, setSessionKnownCount] = useState(0);
  const [sessionUnknownCount, setSessionUnknownCount] = useState(0);
  const [sessionSelections, setSessionSelections] = useState({});
  const [shake, setShake] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);

  useEffect(() => {
    setFlashcards(initialFlashcards);
    setCurrentIndex(0);
    setIsFlipped(false);
    setSessionSelections({});
  }, [initialFlashcards]);

  const handleFlip = () => setIsFlipped(!isFlipped);

  const handleNext = async () => {
    if (sessionSelections[currentFlashcard.id] !== undefined) {
      if (currentIndex < flashcards.length - 1) {
        setCurrentIndex(currentIndex + 1);
        setIsFlipped(false);
      } else {
        setIsCompleting(true);
        await onComplete(sessionKnownCount, sessionUnknownCount);
        setIsCompleting(false);
      }
    } else {
      setShake(true);
      setTimeout(() => setShake(false), 500);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setIsFlipped(false);
    }
  };

  const handleKnown = (known) => {
    const currentSelection = sessionSelections[currentFlashcard.id];
    const newSelections = {
      ...sessionSelections,
      [currentFlashcard.id]: known
    };
    setSessionSelections(newSelections);

    if (currentSelection === undefined) {
      if (known) {
        setSessionKnownCount(prev => prev + 1);
      } else {
        setSessionUnknownCount(prev => prev + 1);
      }
    } else if (currentSelection !== known) {
      if (known) {
        setSessionKnownCount(prev => prev + 1);
        setSessionUnknownCount(prev => prev - 1);
      } else {
        setSessionUnknownCount(prev => prev + 1);
        setSessionKnownCount(prev => prev - 1);
      }
    }
    onAnswer(currentFlashcard.id, known);
  };

  const currentFlashcard = flashcards[currentIndex];
  const isQuestionRTL = isRTL(currentFlashcard.question);
  const isAnswerRTL = isRTL(currentFlashcard.answer);
  const isLastCard = currentIndex === flashcards.length - 1;

  return (
    <div className="flashcards-container w-full">
      <Card className="mb-4 overflow-hidden w-full flashcard-wrapper" style={{ aspectRatio: '21/9', height: '60vh' }}>
        <div
          className="relative w-full h-full cursor-pointer"
          onClick={handleFlip}
        >
          <motion.div
            className="absolute inset-0 w-full h-full [transform-style:preserve-3d]"
            animate={{ rotateY: isFlipped ? 180 : 0 }}
            transition={{ duration: 0.6, type: "spring", stiffness: 50 }}
          >
            <div 
              className="absolute inset-0 flex items-center justify-center p-4 bg-white text-black [backface-visibility:hidden] flashcard-content"
              style={{ direction: isQuestionRTL ? 'rtl' : 'ltr' }}
            >
              <p className="text-3xl sm:text-5xl font-medium overflow-auto max-h-full">{currentFlashcard.question}</p>
            </div>
            <div 
              className="absolute inset-0 flex items-center justify-center p-4 bg-[#B8A6E0] text-white [transform:rotateY(180deg)] [backface-visibility:hidden] flashcard-content"
              style={{ direction: isAnswerRTL ? 'rtl' : 'ltr' }}
            >
              <p className="text-3xl sm:text-5xl font-medium overflow-auto max-h-full">{currentFlashcard.answer}</p>
            </div>
          </motion.div>
        </div>
      </Card>

      <div className="flex justify-between items-center mb-4 navigation-container">
        <Button 
          onClick={handlePrevious} 
          disabled={currentIndex === 0} 
          variant="outline" 
          className="nav-button prev-button text-xs py-1 px-2 no-focus-highlight"
        >
          <ChevronLeft className="h-4 w-4" />
          <span className="hidden sm:inline">Previous</span>
        </Button>
        <span className="card-counter text-sm font-medium px-2">
          Card {currentIndex + 1} of {flashcards.length}
        </span>
        <Button 
          onClick={handleNext} 
          variant="outline"
          className={`nav-button text-xs py-1 px-2 no-focus-highlight ${isLastCard ? 'finish-button' : 'next-button'}`}
          disabled={isCompleting}
        >
          {isCompleting ? (
            <Loader className="h-4 w-4 animate-spin" />
          ) : (
            <>
              <span className="hidden sm:inline">{isLastCard ? 'Finish!' : 'Next'}</span>
              {!isLastCard && <ChevronRight className="h-4 w-4" />}
            </>
          )}
        </Button>
      </div>

      <div className="flex flex-col items-center justify-center mt-4">
        <div className="flex justify-center space-x-4 w-full max-w-md">
          <motion.div 
            className="flex-1"
            animate={shake ? { x: [-10, 10, -10, 10, 0] } : {}}
            transition={{ duration: 0.5 }}
          >
            <Button 
              variant="outline"
              className={`answer-button didnt-know-button w-full ${sessionSelections[currentFlashcard.id] === false ? 'selected' : ''}`}
              onClick={() => handleKnown(false)}
            >
              <X className="h-5 w-5 mr-2 answer-icon" />
              <span>Not Yet</span>
            </Button>
          </motion.div>
          <motion.div 
            className="flex-1"
            animate={shake ? { x: [-10, 10, -10, 10, 0] } : {}}
            transition={{ duration: 0.5 }}
          >
            <Button 
              variant="outline"
              className={`answer-button knew-it-button w-full ${sessionSelections[currentFlashcard.id] === true ? 'selected' : ''}`}
              onClick={() => handleKnown(true)}
            >
              <Check className="h-5 w-5 mr-2 answer-icon" />
              <span>Knew It</span>
            </Button>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
