import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { Pencil, Trash2, Check, X } from 'lucide-react';
import { updateStackName, deleteStack, checkStackExpiration } from '../../services/firestoreService';
import './StackPreview.css';

const StackPreview = ({ stack, onStudyClick, onUpdateStackName, onDeleteStack, newStackCreated }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(stack.name);
  const [isExpired, setIsExpired] = useState(false);

  const formatDate = (date) => {
    if (!date) return 'N/A';
    const d = date instanceof Date ? date : new Date(date);
    return isNaN(d.getTime()) ? 'Invalid Date' : d.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = async () => {
    try {
      await updateStackName(stack.id, editedName);
      onUpdateStackName(stack.id, editedName);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating stack name:", error);
      // Optionally, show an error message to the user
    }
  };

  const handleCancelEdit = () => {
    setEditedName(stack.name);
    setIsEditing(false);
  };

  const handleDeleteClick = async () => {
    const isConfirmed = window.confirm(`Are you sure you want to delete the stack "${stack.name}"? This action cannot be undone.`);
    if (isConfirmed) {
      try {
        await deleteStack(stack.id);
        onDeleteStack(stack.id);
      } catch (error) {
        console.error("Error deleting stack:", error);
        // Optionally, show an error message to the user
      }
    }
  };

  useEffect(() => {
    if (newStackCreated) {
      const stackNameElement = document.querySelector('.stack-name');
      stackNameElement.classList.add('pop-out');
      const timer = setTimeout(() => {
        stackNameElement.classList.remove('pop-out');
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [newStackCreated]);

  useEffect(() => {
    const checkExpiration = async () => {
      if (stack.expiresAt) {
        const isValid = await checkStackExpiration(stack.id);
        setIsExpired(!isValid);
      }
    };
    
    checkExpiration();
  }, [stack.id, stack.expiresAt]);

  return (
    <div className={`stack-preview ${isExpired ? 'expired' : ''}`}>
      <div className="stack-actions">
        {isEditing ? (
          <>
            <Button variant="ghost" size="sm" onClick={handleSaveEdit}>
              <Check size={20} />
            </Button>
            <Button variant="ghost" size="sm" onClick={handleCancelEdit}>
              <X size={20} />
            </Button>
          </>
        ) : (
          <>
            <Button variant="ghost" size="sm" onClick={handleEditClick}>
              <Pencil size={20} />
            </Button>
            <Button variant="ghost" size="sm" onClick={handleDeleteClick}>
              <Trash2 size={20} />
            </Button>
          </>
        )}
      </div>
      <div className="stack-info">
        <div className="info-item">
          <span className="info-label bold">Stack Name:</span>
          {isEditing ? (
            <input
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              className="edit-stack-name"
            />
          ) : (
            <span className="info-value stack-name">{stack.name}</span>
          )}
        </div>
        <div className="info-item">
          <span className="info-label bold">Flashcards:</span>
          <span className="info-value">{stack.flashcardCount}</span>
        </div>
        <div className="info-item">
          <span className="info-label bold">Created Date:</span>
          <span className="info-value">{formatDate(stack.createdAt)}</span>
        </div>
        {stack.expiresAt && (
          <div className="info-item">
            <span className="info-label bold">Expiration Date:</span>
            <span className="info-value">{formatDate(stack.expiresAt)}</span>
          </div>
        )}
      </div>
      
      {isExpired && (
        <div className="expired-banner">
          This stack has expired
        </div>
      )}
      <Button 
        onClick={() => onStudyClick(stack.id)} 
        className="study-button" 
        variant="default"
        disabled={isExpired}
      >
        {isExpired ? 'Expired' : 'Study Now'}
      </Button>
    </div>
  );
};

export default StackPreview;
